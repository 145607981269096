<template>
    <div>
        <!--        heading-->
        <div class="fl-x-cc">
            <h4 class="text-seventh">{{disease.preperation_name}}</h4>
        </div>

        <!--content-->
        <div class="mt-4">
            <p class="text-dark font-weight-600 mb-1">Content</p>
            <div>
                <p class="text-2" v-html="content"></p>
                <!--            <p class="text-4" v-html="item.description"></p>-->
            </div>
        </div>
        <!--        indications-->
        <div class="mt-4">
            <p class="text-dark font-weight-600 mb-1">Indications</p>
            <div>
                <p class="text-2 mt-2" v-for="(item,index) in indications" :key="index">{{item}}</p>
            </div>
        </div>

        <div class="mt-3">
            <!--            <p class="text-dark font-weight-600">Treatment Suggesstions</p>-->
            <!--            <div class="col-lg-12 b-1 mt-3" v-for="(item,index) in suggested_treatment" :key="index">-->
            <!--                <ExpandableCard :title="item.name"-->
            <!--                                :description="item.content"/>-->

            <!--            </div>-->
            <div class="row mt-5">
                <div class="col-lg-6">
                    <p class="text-2 font-proxima-bold">Make Suggestion</p>
                    <validated-text-area v-model="suggested.content"></validated-text-area>
                    <div class="text-center">
                        <btn text="Suggest" color="seventh" :loading="loading" loading-text="Saving..." :border-radius="1" class="px-5" @click="addSuggestion()"></btn>
                    </div>
                </div>
                <div class="col-lg-6">
                    <p class="text-2 font-proxima-bold">Report Disease</p>
                    <validated-text-area v-model="report.content"></validated-text-area>
                    <div class="text-center">
                        <btn text="Report" color="seventh" :loading="loading1" loading-text="Saving..." :border-radius="1" class="px-5" @click="addItem ()"></btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ExpandableCard from '../../../components/cards/ExpandableCard';
// import img1 from '../../../assets/user-home/bull1.png';
// import img2 from '../../../assets/user-home/bull2.png';
// import img3 from '../../../assets/user-home/bull3.png';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'Pharmacy-details-list-page',
    components: {},
    data () {
        return {
            favSataus: '',
            detailsUrl: urls.userHome.preparation.details,
            suggestionUrl: urls.userHome.preparation.suggestPreparation,
            reportUrl: urls.userHome.preparation.reportPreparation,
            loading: false,
            loading1: false,
            response: '',
            data: [],
            disease: '',
            content: [],
            indications: [],
            images: [],
            suggested_treatment: [],
            suggested: {
                content: '',
                preperations: this.$route.params.id
            },
            report: {
                content: '',
                preperation: this.$route.params.id
            }
            /* images: [
                                            {
                                                img: img1,
                                                id: 21,
                                                description: 'sample1',
                                                order: 3334
                                            },
                                            {
                                                img: img2,
                                                id: 22,
                                                description: 'sample',
                                                order: 3334
                                            },
                                            {
                                                img: img3,
                                                id: 23,
                                                description: 'sample',
                                                order: 3334
                                            },
                                            {
                                                img: img1,
                                                id: 24,
                                                description: 'sample',
                                                order: 3334
                                            },
                                            {
                                                img: img2,
                                                id: 25,
                                                description: 'sample',
                                                order: 3334
                                            }
                                        ] */
        };
    },
    mounted () {
        this.fetchItem();
    },
    methods: {

        async addFavourites () {
            try {
                const response = await axios.form(this.favUrl, { id: this.$route.params.id });
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                await this.fetchItem();
                if (result.success === true) {

                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async addSuggestion () {
            try {
                this.loading = true;
                this.response = await axios.form(this.suggestionUrl, this.suggested);

                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Suggestion Added Successfully', 'Success', { type: 'success' });
                    this.suggested.content = '';
                    this.loading = false;
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async addItem () {
            try {
                this.loading1 = true;
                this.response = await axios.form(this.reportUrl, this.report);
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Reported Successfully', 'Success', { type: 'success' });
                    this.report.content = '';
                    this.loading1 = false;
                } else {
                    const errors = this.response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading1 = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchItem () {
            console.log('Fetching details...');
            try {
                const response = await axios.form(this.detailsUrl, { id: this.$route.params.id });
                const result = response.data;
                this.data = result;
                console.log('result', result);
                this.disease = this.data.preperations;
                this.favSataus = this.disease.has_favourited;
                console.log('disease', this.disease);
                this.content = this.data.preperations.content;
                console.log('content', this.content);
                this.indications = this.data.preperations.indications;
                console.log('indications', this.indications);
                this.suggested_treatment = this.data.suggested_treatment;
                console.log('suggested_treatment', this.suggested_treatment);
                // this.images = this.data.images;
                // console.log('images', this.images);
                if (result.success === true) {

                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
